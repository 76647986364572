
export const dropdownvalues = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.name
        m.push(item)
    })
    return m

}
export const dropdownvaluesProjects = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.projectName
        m.push(item)
    })
    return m

}
export const dropdownvaluesUsers = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.fullName
        m.push(item)
    })
    return m

}
export const dropdownvaluesUsersDispatch = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label =  item.employeeId + " - " + item.fullName 
        m.push(item)
    })
    return m

}
export const dropdownvaluesStages = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.name
        m.push(item)
    })
    return m

}
export const dropdownvaluesRegions = (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.regionName
        m.push(item)
    })
    return m

}

export const dropdownvaluesProcess= (data) =>{
    let m = []
    data.forEach((item)=>{
        item.value = item.id
        item.label = item.process_name
        m.push(item)
    })
    return m

}

export const getUserType = () =>{
   let m = localStorage.getItem("userType")
   return m
}
export const getUserId = () =>{
    let u = localStorage.getItem("userId")
    return u
 }

 export const getorg = () =>{
    let u = localStorage.getItem("org_user")
    return u
 }

 export const getIsorg = () =>{
    let u = localStorage.getItem("is_org")
    return u
 }

 export const getDateDiffernce = (date1, date2) =>{
const diffTime = Math.abs(date2 - date1);
const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); 
console.log(diffTime + " milliseconds");
console.log(diffDays + " days")
return diffDays;
 }