import React, { Component, Suspense, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "../app/shared/Spinner";
import { getUserType } from "../utils/helpers";

const Login = lazy(() => import("./user-pages/Login"));
const SignUp = lazy(() => import("./user-pages/SignUp"));
const Country = lazy(() => import("./location/Country"));
const AddCountry = lazy(() => import("./location/AddCountry"));
const EditCountry = lazy(() => import("./location/EditCountry"));
const City = lazy(() => import("./location/City"));
const AddCity = lazy(() => import("./location/AddCity"));
const EditCity = lazy(() => import("./location/EditCity"));
const Area = lazy(() => import("./location/Area"));
const AddArea = lazy(() => import("./location/AddArea"));
const EditArea = lazy(() => import("./location/EditArea"));
const Slot = lazy(() => import("./location/Slot"));
const AddSlot = lazy(() => import("./location/AddSlot"));
const EditSlot = lazy(() => import("./location/EditSlot"));

const Department = lazy(() => import("./staff/Department"));
const AddDepartment = lazy(() => import("./staff/AddDepartment"));
const EditDepartment = lazy(() => import("./staff/EditDepartment"));
const Designation = lazy(() => import("./staff/Designation"));
const AddDesignation = lazy(() => import("./staff/AddDesignation"));
const EditDesignation = lazy(() => import("./staff/EditDesignation"));
const Staff = lazy(() => import("./staff/Staff"));
const AddStaff = lazy(() => import("./staff/AddStaff"));
const EditStaff = lazy(() => import("./staff/EditStaff"));
const Customer = lazy(() => import("./customer/Customer"));
const CustomerService = lazy(() => import("./customer/CustomerServices"));
const AddProject = lazy(() => import("./projects/AddProject"));
const EditProject = lazy(() => import("./projects/EditProject"));
const Project = lazy(() => import("./projects/Project"));
const SubProject = lazy(() => import("./projects/SubProjects"));
const AddSubProject = lazy(() => import("./projects/AddSubProjects"));
const AddClient = lazy(() => import("./Clients/AddClients"));
const EditClient = lazy(() => import("./Clients/EditClients"));
const Client = lazy(() => import("./Clients/Clients"));
const AddRequisition = lazy(() => import("./Requisition/AddRequisition"));
const EditRequisition = lazy(() => import("./Requisition/EditRequisition"));
const AddApproval = lazy(() => import("./Requisition/AddApproval"));
const AddNewProcess = lazy(() => import("./Requisition/AddNewProcess"));
const StagesList = lazy(() => import("./Requisition/StagesList"));
const ViewStages = lazy(() => import("./Requisition/ViewStages"));
const ProcessList = lazy(() => import("./Requisition/ProcessList"));
const Requisition = lazy(() => import("./Requisition/Requisition"));
const AllRequisition = lazy(() => import("./Requisition/AllRequisition"));
const RequestApprove = lazy(() => import("./Requisition/RequestApprove"));
const User = lazy(() => import("./staff/User"));
const AddUser = lazy(() => import("./staff/AddUser"));
const EditUser = lazy(() => import("./staff/EditUser"));
const Dashboard = lazy(() => import("./Dashboard/Dasboard"));
const OrgUser = lazy(() => import("./staff/OrgUser"));
const AddOrgUser = lazy(() => import("./staff/AddOrgUser"));
const EditOrgUser = lazy(() => import("./staff/EditOrgUser"));

const RateMetrix = lazy(() => import("./Requisition/RateMetrix"));
const AddRateMetrix = lazy(() => import("./Requisition/AddRateMetrix"));
const EditRateMetrix = lazy(() => import("./Requisition/EditRateMetrix"));

const Frequency = lazy(() => import("./Requisition/Frequency"));
const AddFrequency = lazy(() => import("./Requisition/AddFrequency"));
const EditFrequency = lazy(() => import("./Requisition/EditFrequency"));

const Acceptance = lazy(() => import("./Requisition/Acceptance"));
const RequestApproveAcceptance = lazy(() =>
  import("./Requisition/RequestApproveAcceptance")
);
const ProcessListAcceptance = lazy(() =>
  import("./Requisition/ProcessListAcceptance")
);
const AddAcceptanceProcess = lazy(() =>
  import("./Requisition/AddAcceptanceProcess")
);
const Attendance = lazy(() => import("./Requisition/Attendance"));
const DispatchRequisition = lazy(() =>
  import("./Requisition/DispatchRequisition")
);

const WeeklyAttendance = lazy(() => import("./Requisition/WeeklyAttendance"));

const AttendanceApproval = lazy(() =>
  import("./Requisition/AttendanceApproval")
);

const AttendanceProcess = lazy(() => import("./Requisition/AttendanceProcess"));
const AddAttendanceProcess = lazy(() =>
  import("./Requisition/AddAttendanceProcess")
);

const AddAttendanceStage = lazy(() =>
  import("./Requisition/AddAttendnaceStage")
);
const ViewAttendanceStage = lazy(() =>
  import("./Requisition/ViewAttendanceStages")
);

const Division = lazy(() => import("./Requisition/DivisionList"));
const AddDivision = lazy(() => import("./Requisition/AddDivision"));
const EditDivision = lazy(() => import("./Requisition/EditDivision"));

const Budget = lazy(() => import("./Budget/Budget"));
const AddBudget = lazy(() => import("./Budget/AddBudget"));
const EditBudget = lazy(() => import("./Budget/EditBudget"));

const Region = lazy(() => import("./Requisition/Region"));
const AddRegion = lazy(() => import("./Requisition/AddRegion"));
const EditRegion = lazy(() => import("./Requisition/EditRegion"));
class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
        </Switch>
        {localStorage?.getItem("token") ? (
          <Switch>
            {/* <Route exact path="/backoffice/dashboard" component={ Dashboard } /> */}

            <Route path="/login" component={Login} />
            <Route path="/dashboard" component={Dashboard} />
            <Route path="/location/country" component={Country} />
            <Route path="/location/add-country" component={AddCountry} />
            <Route path="/location/edit-country/:id" component={EditCountry} />
            <Route path="/location/city" component={City} />
            <Route path="/location/add-city" component={AddCity} />
            <Route path="/location/edit-city/:id" component={EditCity} />
            <Route path="/location/area" component={Area} />
            <Route path="/location/add-area" component={AddArea} />
            <Route path="/location/edit-area/:id" component={EditArea} />
            <Route path="/slot/slot-management" component={Slot} />
            <Route path="/slot/add-slot" component={AddSlot} />
            <Route path="/slot/edit-slot/:id" component={EditSlot} />
            <Route path="/project/edit-project/:id" component={EditProject} />
            <Route path="/project/add-project" component={AddProject} />
            <Route path="/project/project-management" component={Project} />
            <Route path="/project/add-subprojects" component={AddSubProject} />
            <Route path="/project/sub-projects" component={SubProject} />
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/requisition-management"
                component={Requisition}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/attendance-approval"
                component={AttendanceApproval}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/attendance-process"
                component={AttendanceProcess}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/add-new-process"
                component={AddNewProcess}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/requisition/division" component={Division} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/requisition/add-division" component={AddDivision} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/edit-division/:id"
                component={EditDivision}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/requisition/region" component={Region} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/requisition/add-region" component={AddRegion} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/edit-region/:id"
                component={EditRegion}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/budget/budget-management" component={Budget} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/budget/add-budget" component={AddBudget} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/budget/edit-budget/:id/:budgetNo/:divisionId/:regionId"
                component={EditBudget}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/add-attendance-process"
                component={AddAttendanceProcess}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/requisition/frequency" component={Frequency} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/addfrequency"
                component={AddFrequency}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/acceptance/acceptance-process"
                component={ProcessListAcceptance}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/view-stages/:id"
                component={ViewStages}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/add-attendance-stage/:id"
                component={AddAttendanceStage}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/view-attendance-stage/:id"
                component={ViewAttendanceStage}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/acceptance/requestApproveAcceptance"
                component={RequestApproveAcceptance}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/acceptance/requisition-acceptance"
                component={Acceptance}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/acceptance/addAcceptanceProcess"
                component={AddAcceptanceProcess}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/attendance/:id"
                component={Attendance}
              />
            ) : (
              <Redirect to="/login" />
            )}
            <Route
              path="/requisition/editFrequency/:id"
              component={EditFrequency}
            />

            {localStorage?.getItem("token") ? (
              <Route path="/requisition/rateMatrix" component={RateMetrix} />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/addRateMetrix"
                component={AddRateMetrix}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/editRateMetrix/:id"
                component={EditRateMetrix}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/all-requisition-management"
                component={AllRequisition}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route path="/requisition/stages" component={ProcessList} />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/add-requisitions"
                component={AddRequisition}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/request-approve"
                component={RequestApprove}
              />
            ) : (
              <Redirect to="/login" />
            )}

            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/weekly-attendance/:id"
                component={WeeklyAttendance}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/dispatch/:reqId"
                component={DispatchRequisition}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route
                path="/requisition/edit-requisitions/:id"
                component={EditRequisition}
              />
            ) : (
              <Redirect to="/login" />
            )}
            {localStorage?.getItem("token") ? (
              <Route path="/requisition/approvel/:id" component={AddApproval} />
            ) : (
              <Redirect to="/login" />
            )}
            <Route path="/client/edit-client/:id" component={EditClient} />
            <Route path="/client/add-client" component={AddClient} />
            <Route path="/client/client-management" component={Client} />
            <Route path="/staff/department" component={Department} />
            <Route path="/staff/add-department" component={AddDepartment} />
            <Route
              path="/staff/edit-department/:id"
              component={EditDepartment}
            />
            <Route path="/staff/designation" component={Designation} />
            <Route path="/staff/add-designation" component={AddDesignation} />
            <Route
              path="/staff/edit-designation/:id"
              component={EditDesignation}
            />
            <Route path="/staff/staff-management" component={Staff} />
            <Route path="/staff/add-staff" component={AddStaff} />
            <Route path="/staff/edit-staff/:id" component={EditStaff} />

            <Route path="/staff/user-management" component={User} />
            <Route path="/staff/add-user" component={AddUser} />
            <Route path="/staff/edit-user/:id" component={EditUser} />

            <Route path="/staff/orguser-management" component={OrgUser} />
            <Route path="/staff/add-orguser" component={AddOrgUser} />
            <Route path="/staff/edit-orguser/:id" component={EditOrgUser} />
            <Route path="/customers/customer-management" component={Customer} />
            <Route
              path="/customers/customer-request"
              component={CustomerService}
            />

            {localStorage?.getItem("token") ? (
              <Redirect to="/dashboard" />
            ) : (
              <Redirect to="/login" />
            )}
          </Switch>
        ) : (
          <Redirect to="/login" />
        )}
      </Suspense>
    );
  }
}

export default AppRoutes;
